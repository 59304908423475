import { colors } from './../../variables';
import styled, { css } from "styled-components";
import { RouteComponentProps, withRouter } from 'react-router-dom';

export const HeaderWrapper = styled.header`
  height: 50px;
  /* background-color: ${colors.back}; */
  position: relative;
  padding-left: 40px;
  padding-top: 40px;
  align-items: center;
  display: flex;
  @media (max-width: 991px){
    text-align: center;
    display: block;
    padding-left: 0px;
    padding-top: 15px;
  }
`;

const _Logo = styled.img<RouteComponentProps>`
  width: 220px;
  height: auto;
  position: fixed;
  left: 40px;
  top: 20px;
  color: #000;
  text-transform: uppercase;
  font-style: italic;
  margin-right: 20px;
  @media (max-width: 991px){
    width: 200px;
    position: relative;
    top: inherit;
    z-index: 2;
    left: inherit;
    text-align: center;
    margin: 0 auto;
    top: 0px;
  }
  ${props => (
    props.match.path.startsWith('/message') ||
    props.match.path.startsWith('/test')
  ) && css`
    @media (max-width: 991px){
      filter: invert(0);
    }
    
  `
  }
`;

const _Catchline = styled.h1<RouteComponentProps>`
  position: relative;
  font-size: 19px;
  font-weight: 600;
  color: #fff;
  position: fixed;
  right: 350px;
  top: 42px;
  text-align: right;
  @media (max-width: 991px){
    position: relative;
    text-align: center;
    left: inherit;
    font-size: 28px;
    margin-top: 0px;
    top: 10px;
    position: relative;
    display: none;
  }
  @media (max-width: 767px){
    font-size: 20px;
  }

  ${props => (
    props.match.path.startsWith('/preview')
  ) && css`
    display: none;
    
  `
  }

${props => (
    props.match.path.startsWith('/preview') ||
    props.match.path.startsWith('/record') ||
    props.match.path.startsWith('/message')
  ) && css`
    @media (max-width: 991px){
      display: none;
    }
    
  `
  }
`;

export const Catchline = withRouter(_Catchline);
export const Logo = withRouter(_Logo);