import styled, { css } from "styled-components";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import backgroundDesktop1 from './images/background_desktop1.jpg';
import backgroundDesktop2 from './images/background_desktop2.jpg';

export const MainWrapper = styled.div`
  background-color: #EC1B66;
  position: relative;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
`;


const _PictureBackground = styled.div<RouteComponentProps>`
    position: fixed;
    left: 0;
    top: 0;
    min-width: 100%;
    width: 100%;
    height: 100%;
    background-size: cover;
    object-fit: cover;
    background-color: #EC1B66;
    background-size: cover;
    background-position: bottom left;
    background-image: url(${backgroundDesktop1});
    background-repeat: no-repeat;

    ${props => (
      props.match.path.startsWith('/message')
    ) && css`
      background-image: url(${backgroundDesktop2});
    `
    }
    @media (max-width: 991px){
      background-image: inherit;
      background-color: #EC1B66;
    }
`;



export const PictureBackground = withRouter(_PictureBackground);