import * as React from 'react';
import { FooterWrapper, ListFooterLink, Image } from './styled';
import { Link } from 'react-router-dom';
import image from './images/mobile.png';
import {FormattedMessage} from 'react-intl';


const Footer:React.FunctionComponent = () => {
  
    return <FooterWrapper>
      {/* Suppression de l'alcool .... */}
      <ListFooterLink className="right">
        <li><a href="http://www.meemento.com" target="_blank"><FormattedMessage id="global_txt_footer_editedby"/></a></li>
        <li><a href="https://www.meemento.com/docs/ml_fr.pdf" target="_blank"><FormattedMessage id="global_txt_footer_legal"/></a></li>
        <li><a href="https://www.meemento.com/docs/cgu_fr.pdf" target="_blank"><FormattedMessage id="global_txt_footer_tandc"/></a></li>
        <li><a href="https://www.meemento.com/docs/pc_fr.pdf" target="_blank"><FormattedMessage id="global_txt_footer_privacy"/></a></li>
      </ListFooterLink>
      <Image src={image} />
    </FooterWrapper>;
}

export default Footer;