import * as React from 'react';
import Layout from '../layout';
import { SortWrapper, SortMessage, SortTitle, SortSubtitle, Sep, SortContent, SortFormContainer, SortForm, InputField, InputGroup, ButtonGroup, InputInfoIcon, InputInfo } from './styled';
import { Button } from '../../widgets/globaleStyledWidget';
import { colors } from '../../variables';

import {FormattedMessage} from 'react-intl';
import livstickApp from '../../models/livstick';
import { useHistory } from 'react-router';



const SortPage:React.FunctionComponent = () => {

    const history = useHistory();

    return <Layout>
      
      <SortWrapper>
        <SortContent>
          <SortTitle><FormattedMessage id="sort_txt_title1" defaultMessage=""/></SortTitle>
          <SortMessage><FormattedMessage id="sort_txt_text1" defaultMessage=""/></SortMessage>
          <ButtonGroup>
            <Button color={colors.special} hover="special" onClick={() => {
              livstickApp.ordType = '1';
              history.push('/record');
            }}><FormattedMessage id="sort_txt_button1" defaultMessage=""/></Button>
          </ButtonGroup>
          <Sep/>
          <SortSubtitle><FormattedMessage id="sort_txt_title2" defaultMessage=""/></SortSubtitle>
          <SortMessage><FormattedMessage id="sort_txt_text2" defaultMessage=""/></SortMessage>
          <ButtonGroup>
            <Button color={colors.secondary} hover="secondary" onClick={() => {
              livstickApp.ordType = '2';
              history.push('/record');
            }}><FormattedMessage id="sort_txt_button2" defaultMessage=""/></Button>
          </ButtonGroup>
        </SortContent>
      </SortWrapper>

    </Layout>;
}

export default SortPage;